<template>
	<!-- 汇总 -->
	<z-plate has-bottom-border>
		<!-- title 左侧 -->
		<template #titleLeft>
			<span class="title">评分明细</span>
			<div class="title-mini">
				<strong class="label">分类：</strong>
				<span class="content">{{ groupChannel }}</span>

				<strong class="label">视频编码：</strong>
				<span class="content">{{ randomCode }}</span>

				<strong class="label">作品名称：</strong>
				<span class="content">{{ videoName }}</span>

				<strong class="label">上报单位：</strong>
				<span class="content">{{ schoolName }}</span>
			</div>
		</template>

		<!-- title 右侧按钮 -->
		<template #titleRight>
			<el-button @click="exportDetail">
				<img src="../../assets/img/icon-export.png" alt class="icon" /> 导出
			</el-button>
		</template>

		<div id="tableDiv" style="width: 98%; overflow-x: auto; margin: 10px;">
			<!-- 表格 -->
			<table id="tableData" v-if="tableData.length">
				<tr>
					<td width="45" class="itemName"> 序号 </td>
					<td width="80" class="itemName"> 评委姓名 </td>
					<td width="50" class="itemName"> 总分 </td>

					<td width="110" class="itemName" v-for="item in tableData[0].itemList"> {{ item.itemName }} </td>

					<td width="110" class="itemName"> 评语 </td>
				</tr>

				<tr v-for="(item1, index1) in tableData" >
					<td>{{ index1 + 1 }}</td>
					<td>{{ item1.teacherName }}</td>
					<td>{{ item1.totalScore }}</td>

					<td v-for="item3 in tableData[0].itemList" v-if="item1.itemList == null">
						<strong class="color-primary"> - </strong>
					</td>

					<td v-for="item2 in item1.itemList" v-if="item1.itemList != null">
						<strong class="color-primary" v-if="item2.score != '' && item2.score > 0">{{ item2.score }}</strong>
						<strong class="color-primary" v-else> 0 </strong>
					</td>

					<td :title="item1.content" class="contentDetail">{{ item1.content }}</td>
				</tr>

			</table>

			<v-nodata v-else></v-nodata>
		</div>

		<!-- 分页 -->
		<!--<z-pagination v-if="pagination.total > 0" :page.sync="pagination.page" :limit.sync="pagination.limit" :total="pagination.total" @pagination="init"></z-pagination>-->
	</z-plate>
</template>

<script>

export default {
	data() {
		return {
			tableData: [],
			groupChannel: '',
			videoName: '',
			schoolName: '',
			randomCode: '',
			pagination: {
				page: 0, //当前页
				limit: 10, //分页条数
				total: 0 //总页数
			},
			tableLoading: false,
			activityId: sessionStorage.getItem("group_activityid"),
			videoId: sessionStorage.getItem("videoId")
		};
	},

	mounted() {
		this.init();
	},

	methods: {
		init() {
			this.tableLoading = true;
			this.$post("/queryVideoScoreDetai", {
				videoId: this.videoId
			}).then(m => {
				if (m.resultCode == 0) {
					this.tableData = m.content.list;
					this.groupChannel = m.content.groupChannel;
					this.videoName = m.content.videoName;
					this.schoolName = m.content.schoolName;
					this.randomCode = m.content.randomCode;

					this.tableLoading = false;
				}
			})

			$("#tableDiv").scroll(function () {//给table外面的div滚动事件绑定一个函数
				var left = $("#tableDiv").scrollLeft();//获取滚动的距离
				var trs = $("#tableDiv table tr");//获取表格的所有tr
				trs.each(function (i) {//对每一个tr（每一行）进行处理
					//获得每一行下面的所有的td，然后选中下标为0的，即第一列，设置position为相对定位
					//相对于父div左边的距离为滑动的距离，然后设置个背景颜色，覆盖住后面几列数据滑动到第一列下面的情况
					//如果有必要也可以设置一个z-index属性
					$(this).children().eq(0).css({ "position": "relative", "top": "0px", "left": left, "background-color": "white" });
					$(this).children().eq(1).css({ "position": "relative", "top": "0px", "left": left, "background-color": "white" });
					$(this).children().eq(2).css({ "position": "relative", "top": "0px", "left": left, "background-color": "white" });
				});
			});

		},

		exportDetail() {
			if (!this.tableData.length) {
				this.$message({
					type: 'info',
					message: '暂无导出数据信息'
				});
				return;
			}
			window.open("api/exportVideoScoreDetai?videoId=" + this.videoId + "&access_token=" + sessionStorage.getItem("ACCESS_TOKEN"));
		}
	}
};
</script>

<style lang="scss" scoped>
.title-mini {
	display: inline-block;
	font-size: 14px;
	color: #666;
	margin-left: 36px;

	.content {
		margin-right: 20px;
	}
}

.contentDetail {
	max-width: 110px;
	/* 规定段落中的文本不进行换行： */
	white-space: nowrap;
	/* 内容会被修剪，并且其余内容是不可见的。 */
	overflow: hidden;
	/* 显示省略符号来代表被修剪的文本。 */
	text-overflow: ellipsis;
}

//表格固定布局
#tableData {
	table-layout: fixed;
	word-break: break-all;
	word-wrap: break-word;
	margin-bottom: 10px;
}
</style>
